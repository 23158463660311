import { ComparatorButton, ComparatorSection } from '@/shared/types/comparator'

export function getDataComparator({ setModalVisible }) {
  const COMPARATOR_HEADER: ComparatorButton[] = [
    {
      logo: '/assets/images/logo_free_transfert.svg',
      label: "Service d'envoi de fichiers gratuit pour tous"
    },
    {
      logo: '/assets/images/logo_free_transfert_premium.svg',
      label: 'Réservé aux abonnés connectés sur leur réseau Free',
      button: {
        label: "S'abonner",
        onClick: () => {
          setModalVisible(true)
        }
      }
    }
  ]
  return COMPARATOR_HEADER
}

export const COMPARATOR_DATA: ComparatorSection[] = [
  {
    icon: 'file',
    title: "Taille d'envoi de fichiers",
    standardItems: ['10 Go par transfert'],
    premiumItems: ['Illimitée'],
    tag: 'Nouveau'
  },
  {
    icon: 'timer',
    title: 'Validité du transfert',
    standardItems: ["Jusqu'à 7 jours"],
    premiumItems: ['Jusqu’à 30 jours']
  },
  {
    icon: 'add-people',
    title: 'Nombre de destinataires',
    standardItems: ["Jusqu'à 10"],
    premiumItems: ["Jusqu'à 50"]
  },
  // {
  //   icon: 'file',
  //   title: 'Type de fichiers',
  //   standardItems: ['Envoi de fichiers'],
  //   premiumItems: ['Envoi de fichiers et dossiers']
  // },
  // TO KEEP WHEN FILES WILL BE OK FOR UPLOAD//
  {
    icon: 'file-upload',
    title: 'Type de transfert',
    standardItems: ['Par e-mail', 'Par lien partageable'],
    premiumItems: ['Par e-mail', 'Par lien partageable']
  },
  {
    icon: 'lock',
    title: 'Sécurité',
    standardItems: ['Protection avec mot de passe', 'Transfert sécurisé https', 'Stockage des données en France'],
    premiumItems: ['Protection avec mot de passe', 'Transfert sécurisé https', 'Stockage des données en France']
  }
]
