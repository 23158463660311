import clsx from 'clsx'
import { ETitleType, Title } from '@/components/ui/Title/Title'
import { Button, ButtonProps } from '@transverse/digitalsystems-library'

type OfferSelectPropsType = {
  redirectFreeMobile: () => void
  redirectFreeBox: () => void
}
type ImageType = {
  src: string
  alt: string
}

declare type ClassNameType = {
  className?: string
}

type OfferSectionModalType = {
  image: ImageType
  title: string
  button: ButtonProps
}

export type OfferSectionProps = OfferSectionModalType &
  ClassNameType & {
    onClick: () => void
  }

const OfferSection = (props: OfferSectionProps) => {
  const { image, title, button, className, onClick } = props

  const sectionClass = clsx(
    'flex flex-col w-full items-center justify-end p-6 tablet:py-6 px-22 laptop:py-10 px-14',
    className
  )

  return (
    <div className={sectionClass}>
      <img src={image.src} alt={image.alt} className="h-20 w-20" />
      <Title title={title} type={ETitleType.H3} className="mt-2 font-bold text-center text-14 laptop:text-20 " />
      <Button {...button} size="m" className="mt-2 min-w-[160px] laptop:min-w-[300px]" onClick={onClick} />
    </div>
  )
}

export const OfferSelect = ({ redirectFreeMobile, redirectFreeBox }: OfferSelectPropsType) => (
  <div className="flex flex-col items-stretch justify-center w-full tablet:flex-row">
    <OfferSection
      title="Offres mobiles Free"
      image={{ src: 'assets/images/Mobile_Sim.svg', alt: 'illu_mobile_sim' }}
      button={{ label: 'Continuer' }}
      onClick={redirectFreeMobile}
    />
    <OfferSection
      title="Offres Freebox"
      image={{ src: 'assets/images/FreeboxPop.svg', alt: 'illu_recepteur_freebox' }}
      button={{ label: 'Continuer', variant: 'secondary' }}
      className="bg-gray-100"
      onClick={redirectFreeBox}
    />
  </div>
)
