import { useContext, useEffect, useState } from 'react'
import { freeboxAdds, freeMobileAdds, IAddCard } from '@/data/addCards'
import { AppContext } from '@/shared/contexts/AppContext'
import AddCard from '@/components/ui/Card/AddCard'
import { ESubscription } from '@/shared/types'

const RandomAddCard = () => {
  const [addCard, setAddCard] = useState<IAddCard>()
  const { subscription } = useContext(AppContext)

  useEffect(() => {
    const adds =
      subscription === ESubscription.FREE_MOBILE
        ? freeboxAdds
        : subscription === ESubscription.FREEBOX
        ? freeMobileAdds
        : [...freeboxAdds, ...freeMobileAdds]
    const randomIndex = adds.length > 1 ? Math.floor(Math.random() * adds.length) : 0
    setAddCard(adds[randomIndex])
  }, [])

  return <AddCard onClick={addCard?.onClick} background={addCard?.background} />
}

export default RandomAddCard
