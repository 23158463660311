import { Button, IconNameType } from '@transverse/digitalsystems-library'
import clsx from 'clsx'

export type ActionButtonType = {
  label: string
  onClick: () => void
  icon?: IconNameType
  disabled?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
  type?: 'button' | 'submit'
  isLoading?: boolean
}

export type BottomActionsType = {
  buttons: ActionButtonType[]
  className?: string
  vertical?: boolean
  fullWidth?: boolean
}

const BottomActions = ({ buttons, className, vertical, fullWidth }: BottomActionsType) => {
  return (
    <div
      className={clsx(
        'sticky bottom-0 flex justify-end border-t border-gray-100 bg-white p-4',
        className,
        vertical && 'flex-col'
      )}
    >
      {buttons.map(({ type = 'button', variant = 'primary', label, icon, onClick, disabled, isLoading }, index) => (
        <Button
          key={`bottom-actions-${label}`}
          type={type}
          label={label}
          icon={icon}
          size="m"
          variant={variant}
          className={clsx(
            (buttons.length === 1 || fullWidth) && 'w-full',
            index !== 0 && !vertical && 'ml-2',
            index !== 0 && vertical && 'mt-2'
          )}
          onClick={onClick}
          disabled={disabled}
          isLoading={isLoading}
        />
      ))}
    </div>
  )
}

export default BottomActions
