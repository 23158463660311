import clsx from 'clsx'
import { useState } from 'react'
import { Title } from '@/components/ui/Title/Title'
import { Button, Modal } from '@transverse/digitalsystems-library'
import { COMPARATOR_DATA, getDataComparator } from '@/data/comparator'
import { HOME_FREEBOX_URL, HOME_FREE_MOBILE_URL } from '@/shared/constants'
import { OfferSelect } from '@/components/templates/Shared/Modal/OfferSelect'

export const ComparatorBody = () => {
  const redirectFreeMobileURL = () => window.open(HOME_FREE_MOBILE_URL)
  const redirectFreeBoxURL = () => window.open(HOME_FREEBOX_URL)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const COMPARATOR_HEADER = getDataComparator({ setModalVisible })

  return (
    <>
      <Title
        title="Choisissez l’offre qui vous correspond"
        className="pt-12 text-24 font-bold font-iliad laptop:text-40"
      />
      <div className="laptop:max-w-3xl flex flex-col laptop:mx-auto py-6 desktop: mt-10">
        <div className="flex tablet:justify-between">
          {COMPARATOR_HEADER.map(({ logo, label, button }) => (
            <div
              key={`comparator-body-header-${label}`}
              className="flex flex-col items-center justify-start w-1/2 tablet:w-1/4 mx-4 laptop:min-w-[250px] pb-10"
            >
              <img src={logo} alt={logo} className="w-32" />
              <span className={clsx('my-6 text-center text-14 desktop:text-16', button ? 'mt-4' : 'mt-[28px]')}>
                {label}
              </span>

              {button && <Button {...button} className="w-30 tablet:w-34 laptop:w-34" />}
            </div>
          ))}
        </div>

        {COMPARATOR_DATA.map(({ title, standardItems, premiumItems, tag }) => (
          <div key={`comparator-body-data-${title}`} className="flex flex-col">
            <div className="flex justify-center items-center w-full">
              <div className="w-full h-1 border-t border-gray-100" />
              <div>
                <span className="text-center text-14 desktop:text-16 font-semibold whitespace-nowrap px-2">
                  {title}
                </span>
              </div>
              <div className="w-full h-1 border-t border-gray-100" />
            </div>

            <div className="flex items-center justify-center gap-7 tablet:gap-27 py-10">
              <div className="flex flex-col w-1/3 tablet:w-1/4">
                {standardItems.map((item) => (
                  <span
                    key={`comparator-body-data-standard-items-${item}`}
                    className="text-center text-14 desktop:text-16 pt-4 laptop:pt-8 first:pt-0"
                  >
                    {item}
                  </span>
                ))}
              </div>
              <div className="flex flex-col w-1/3 tablet:w-1/4">
                {tag && (
                  <span className="text-status-info self-center text-12 font-semibold bg-blue-50 border-none px-2 laptop:-mb-[18px] rounded-full">
                    {tag}
                  </span>
                )}
                {premiumItems.map((item) => (
                  <span
                    key={`comparator-body-data-premium-items-${item}`}
                    className="text-center text-14 desktop:text-16 pt-4 laptop:pt-8 first:pt-0"
                  >
                    {item}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal title={''} visible={modalVisible} onClose={() => setModalVisible(false)}>
        <OfferSelect redirectFreeMobile={redirectFreeMobileURL} redirectFreeBox={redirectFreeBoxURL} />
      </Modal>
    </>
  )
}
