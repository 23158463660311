import React, { Fragment } from 'react'
import clsx from 'clsx'
import { ETheme } from '@/shared/types'

export enum ETitleType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3'
}
interface ITitle {
  title: string | undefined
  type?: ETitleType
  className?: string
  theme?: ETheme
  style?: Object
}

export const Title = (props: ITitle) => {
  const { title, type = ETitleType.H2, className, theme = ETheme.light, style } = props
  const isH1Title = type === ETitleType.H1
  const isH3Title = type === ETitleType.H3

  const titleCSSManagement = () => {
    if (isH1Title) {
      return 'flex flex-col font-bold text-28 desktop-sm:text-32 desktop-sm:mb-6'
    } else if (isH3Title) {
      return 'mt-4 mb-4 font-semibold text-16 text-left'
    }
    return 'flex flex-col font-bold text-24'
  }

  const titleClassName = clsx(theme === ETheme.light ? '' : 'text-white', className, titleCSSManagement())

  return React.createElement(
    type,
    { className: titleClassName },
    <Fragment>
      <span className="relative text-center" style={style}>
        {title}
      </span>
    </Fragment>
  )
}
