import React, { ReactNode, useContext, useMemo } from 'react'
import clsx from 'clsx'
import BottomActions, { BottomActionsType } from '@/components/templates/Shared/BottomActions/BottomActions'
import DiscoverPremiumCard from '@/components/ui/Card/DiscoverPremiumCard'
import { AppContext } from '@/shared/contexts/AppContext'
import RandomAddCard from '@/components/templates/Shared/RandomAddCard/RandomAddCard'
import { StepType } from '@/shared/types/upload'

const { motion } = require('framer-motion')

interface IResponsiveLayout {
  leftBlock: ReactNode
  bottomActionParams?: BottomActionsType
  headerBackground?: boolean
  currentStep?: StepType
}

const ResponsiveLayout = ({ currentStep, leftBlock, bottomActionParams }: IResponsiveLayout) => {
  const { isSubscriber } = useContext(AppContext)
  const { progression, stepNumber, title } = currentStep ?? {}

  const cardClass =
    'tablet:rounded-16 tablet:w-[50vw] tablet:h-[63vw] tablet:max-w-[536px] tablet:max-h-[685px]  desktop:w-[33vw] desktop:h-[42vw]'

  const progressionStyle = useMemo(() => ({ width: progression ? `${progression}%` : '0' }), [currentStep])

  return (
    <div className="flex flex-col w-full grow laptop:min-h-[calc(100vh-265px)] laptop:mt-4">
      <div className="flex grow w-full tablet:justify-center shrink-0">
        <div
          className={clsx(
            cardClass,
            'w-full tablet:bg-white tablet:mr-6 tablet:ml-2 desktop-md:m-6 flex flex-col tablet:justify-between relative overflow-hidden'
          )}
        >
          {progression && <div style={progressionStyle} className="h-[5px] bg-gray-700 transition-all duration-500" />}

          <motion.div
            key={stepNumber}
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 0, opacity: 0 }}
            transition={{ easeIn: [], duration: 0.5 }}
            className="tablet:overflow-auto h-full flex flex-col flex-1 justify-between tablet:p-6 p-4"
          >
            <div className="h-full">
              {title && stepNumber && (
                <div className="flex items-center pb-6 tablet:pb-8">
                  <div className="min-w-10">
                    <div className="rounded-full border-2 border-gray-700 w-10 h-10 font-semibold text-20 text-center leading-9">
                      {stepNumber}
                    </div>
                  </div>
                  <div className="ml-4 font-semibold text-18">{title}</div>
                </div>
              )}

              {leftBlock}
            </div>
          </motion.div>

          {!isSubscriber && (
            <div className="m-4 mb-2">
              <DiscoverPremiumCard currentStep={currentStep?.stepNumber} />
            </div>
          )}

          {bottomActionParams && (
            <BottomActions
              {...bottomActionParams}
              className="hidden tablet:flex tablet:w-auto m-[-16px] mb-[-24px] tablet:p-6 tablet:m-0 tablet:rounded-b-16"
            />
          )}
        </div>

        <div
          className={clsx(
            cardClass,
            'hidden tablet:mr-2 tablet:ml-6 desktop-md:m-6 w-full tablet:flex tablet:justify-center'
          )}
        >
          <RandomAddCard />
        </div>
      </div>

      {bottomActionParams && <BottomActions {...bottomActionParams} className="tablet:hidden" />}
    </div>
  )
}

export default ResponsiveLayout
