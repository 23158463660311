interface IAddCard {
  background?: string
  onClick?: () => void
}

const AddCard = ({ background, onClick }: IAddCard) => {
  return (
    <div
      className="w-full h-full bg-white rounded-16 overflow-hidden cursor-pointer bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: `url('${background}')` }}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
    ></div>
  )
}

export default AddCard
