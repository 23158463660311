import { useState } from 'react'
import { Modal, ParseString } from '@transverse/digitalsystems-library'
import { EUploadStep } from '@/shared/types/upload'
import ComparatorModal from '@/components/templates/Shared/Modal/ComparatorModal'

const CARD_LABELS = {
  [EUploadStep.LIST]: 'Vous avez besoin  ~~de transférer des fichiers de taille illimitée ~~ ?',
  [EUploadStep.VALIDITY]: "Vous avez besoin d'un ~~délai de validité plus long~~ ?",
  [EUploadStep.RECIPIENT]:
    "Vous avez besoin ~~de transférer des fichiers de taille illimitée~~ et ~~jusqu'à 50 destinataires~~ ?"
}

const DiscoverPremiumCard = ({ currentStep }) => {
  if (!CARD_LABELS[currentStep]) {
    return <></>
  }

  const [comparatorVisible, setComparatorVisible] = useState<boolean>(false)
  const sideDrawerClosedHandler = () => {
    setComparatorVisible(false)
    document.body.style.overflow = 'unset'
  }

  const showSidebar = () => {
    setComparatorVisible(true)
    if (typeof window !== 'undefined' && window.document) {
      document.body.style.overflow = 'hidden'
    }
  }

  return (
    <div className="flex flex-col items-center border rounded-8 border-blue-200 bg-blue-50 p-4 my-2 text-14 text-center">
      <div className="text-gray-700">
        <ParseString>{CARD_LABELS[currentStep]}</ParseString>
      </div>
      <button className="text-status-info font-semibold mt-2 cursor-pointer" onClick={showSidebar}>
        Découvrez Free Transfert Premium
      </button>
      <Modal visible={comparatorVisible} title="Découvrez Free Transfert Premium" onClose={sideDrawerClosedHandler}>
        <ComparatorModal />
      </Modal>
    </div>
  )
}

export default DiscoverPremiumCard
