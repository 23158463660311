import { ComparatorBody } from '../ComparatorBody/ComparatorBody'

const ComparatorModal = () => {
  return (
    <section id="comparatorModal" className="flex-1 max-w-3xl mt-4 overflow-auto">
      <img
        src="/assets/images/illu_modal_comparator.svg"
        alt="header_discover_free_transfert_premium"
        className="w-full"
      />
      <div className="bg-gray-50 rounded-16 mx-2 desktop:mx-6 mb-6">
        <ComparatorBody />
      </div>
    </section>
  )
}

export default ComparatorModal
